import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import i18n from '@/libs/i18n'
import ErrorHandler from '@/utils/error-handler'
import {
  confirmConfig, errorConfig, inputConfig, successConfig, infoConfig, warningConfig,
} from './swal-alert-config.js'

Vue.use(VueSweetalert2, infoConfig())

// eslint-disable-next-line no-param-reassign,no-multi-assign
Vue.prototype.$swalConfirm = (text, options = {}) => Vue.prototype.$swal({ ...confirmConfig(), ...options, titleText: text })

// eslint-disable-next-line no-param-reassign,no-multi-assign
Vue.prototype.$swalInput = options => Vue.prototype.$swal({ ...inputConfig(), ...options })

// eslint-disable-next-line no-param-reassign,no-multi-assign
Vue.prototype.$swalError = (error, options = {}) => {
  console.error(error)
  const { message, title } = ErrorHandler.getErrorDetails(error)
  return Vue.prototype.$swal({ ...errorConfig(), ...options, html: message || title })
}

// eslint-disable-next-line no-param-reassign,no-multi-assign
Vue.prototype.$swalSuccess = (message = '', options = {}) => Vue.prototype.$swal({ ...successConfig(), ...options, text: message })

// eslint-disable-next-line no-param-reassign,no-multi-assign
Vue.prototype.$swalWarning = (message = '', options = {}) => Vue.prototype.$swal({
  ...warningConfig(), icon: 'warning', titleText: i18n.t('errors.warning'), ...options, text: message,
})
