import i18n from '@/libs/i18n'

export const confirmConfig = () => ({
  icon: 'warning',
  title: i18n.t('forms.are-you-sure'),
  text: i18n.t('forms.not-able-revert'),
  confirmButtonText: i18n.t('forms.confirm'),
  cancelButtonText: i18n.t('forms.cancel'),
  showCancelButton: true,
  showLoaderOnConfirm: true,
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-outline-primary',
  },
})

export const warningConfig = () => ({
  icon: 'warning',
  title: i18n.t('errors.warning'),
  confirmButtonText: i18n.t('forms.ok'),
  showCancelButton: false,
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-outline-primary',
  },
})

export const errorConfig = () => ({
  icon: 'error',
  titleText: `${i18n.t('errors.error')}`,
  confirmButtonText: i18n.t('forms.ok'),
  showCancelButton: false,
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-danger',
    cancelButton: 'btn btn-outline-danger',
  },
})

export const inputConfig = () => ({
  icon: '',
  titleText: '',
  confirmButtonText: i18n.t('forms.submit'),
  cancelButtonText: i18n.t('forms.cancel'),
  showCancelButton: true,
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-outline-primary',
  },
})

export const successConfig = () => ({
  icon: 'success',
  titleText: i18n.t('forms.submitted'),
  confirmButtonText: i18n.t('forms.ok'),
  showCancelButton: false,
  timer: 3500,
  timerProgressBar: true,
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-outline-success',
  },
})

export const infoConfig = () => ({
  icon: 'info',
  titleText: '',
  confirmButtonText: i18n.t('forms.ok'),
  cancelButtonText: i18n.t('forms.cancel'),
  showCancelButton: true,
  showLoaderOnConfirm: true,
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-outline-primary',
  },
})
